
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { useAppBase } from '@/core/composables/AppBase';
import { IParametrosBuscaRapida } from '@/core/models/Consulta/IParametrosBuscaRapida';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoFormaPagamento from '@/servicos/ServicoFormaPagamento';

export default defineComponent({
  name: 'ComboFormaPagamento',
  props: {
    multiplaSelecao: {
      type: Boolean,
      default: false,
    },
    codigo: {
      type: Number || undefined,
    },
    codigos: {
      type: Array as () => number[],
      default: () => [],
    },
    ignorarCodigos: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigo', 'update:codigos', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesBuscaRapida } = useAppBase();

    let debounce = 0;
    const servicoFormaPagamento = new ServicoFormaPagamento();

    const state = reactive({
      opcoesFormaPagamentos: [] as IOption[],
      buscando: false,
      digitando: false,
    });

    async function obterPorCodigo(valor: any) {
      if (state.digitando) { return; }
      if (!UtilitarioGeral.valorValido(valor)) { return; }

      if (props.multiplaSelecao) {
        if (props.codigos.length === 0) { return; }
      } else if (props.codigo === 0) {
        return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.opcoesFormaPagamentos)) {
        if (props.multiplaSelecao) {
          const listaCodigosConsulta = state.opcoesFormaPagamentos.map((c) => c.value);
          const diferencas = props.codigos.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.opcoesFormaPagamentos.find((c) => c.value === props.codigo);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.opcoesFormaPagamentos = [];
      const parametrosBuscaRapida: IParametrosBuscaRapida = { apenasAtivos: false };

      if (props.multiplaSelecao) {
        parametrosBuscaRapida.filtrarPorVariosCodigos = true;
        parametrosBuscaRapida.valores = valor;
      } else {
        parametrosBuscaRapida.filtrarPorCodigo = true;
        parametrosBuscaRapida.valor = valor;
      }

      const listaBuscaRapida = await servicoFormaPagamento.buscaRapida(parametrosBuscaRapida);
      state.opcoesFormaPagamentos = montaOpcoesBuscaRapida(listaBuscaRapida);
      if (!UtilitarioGeral.validaLista(state.opcoesFormaPagamentos)) {
        if (props.multiplaSelecao) {
          emit('update:codigos', undefined);
        } else {
          emit('update:codigo', undefined);
        }
      }
    }

    async function pesquisar(valor: any) {
      state.digitando = true;

      state.buscando = true;
      state.opcoesFormaPagamentos = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosBuscaRapida: IParametrosBuscaRapida = { valor, apenasAtivos: true };

        let listaBuscaRapida = await servicoFormaPagamento.buscaRapida(parametrosBuscaRapida);
        if (props.ignorarCodigos.length > 0) {
          props.ignorarCodigos.forEach((codigo) => {
            listaBuscaRapida = listaBuscaRapida.filter((c) => c.codigo !== codigo);
          });
        }
        state.buscando = false;
        state.opcoesFormaPagamentos = montaOpcoesBuscaRapida(listaBuscaRapida);
      }, 600);
    }

    function obterFormaPagamento():any {
      obterPorCodigo(props.codigo);
      return props.codigo;
    }

    const computedCodigo = computed({
      get: () => obterFormaPagamento(),
      set: (val: number) => {
        emit('update:codigo', val);
      },
    });

    function obterFormaPagamentos():number[] {
      obterPorCodigo(props.codigos);
      return props.codigos;
    }

    const computedCodigos = computed({
      get: () => obterFormaPagamentos(),
      set: (val: number[]) => {
        emit('update:codigos', val);
      },
    });

    function change() {
      emit('change', props.multiplaSelecao ? props.codigos : props.codigo);
    }

    return {
      props,
      state,
      computedCodigo,
      computedCodigos,
      pesquisar,
      change,
    };
  },
});
