
import {
  defineComponent, onBeforeMount, onMounted, reactive, ref, watch, computed,
} from 'vue';
import { useElementSize } from '@vueuse/core';
import { useRoute } from 'vue-router';
import storeApp from '@/store/storeApp';
import { useAppBase } from '@/core/composables/AppBase';
import {
  IArea, IAreaBox, IAreaBoxStatus,
} from '@/models/Entidades/IArea';
import ServicoReserva from '@/servicos/ServicoReserva';
import { EBoxStatus } from '@/models/Enumeradores/EBoxStatus';
import GerenciadorAutenticacao from '@/core/gerenciadores/GerenciadorAutenticacao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IDTOAreaDisponibilidade } from '@/models/DTO/IDTOAreaDisponibilidade';
import { IReserva, IReservaEquipamento } from '@/models/Entidades/IReserva';
import { ICliente } from '@/models/Entidades/ICliente';
import ServicoUtilitario from '@/servicos/ServicoUtilitario';
import { IFormaPagamento } from '@/models/Entidades/IFormaPagamento';
import { ETipoControleArea } from '@/models/Enumeradores/ETipoControle';
import { IEquipamento } from '@/models/Entidades/IEquipamento';
import { IDTOReservaCartaoDadosCartao, IDTOReservaCartaoDadosTitularCartao, IDTOReservaCriacao } from '@/models/DTO/IDTOReservaCriacao';
import { EStatusReserva } from '@/models/Enumeradores/EStatusReserva';
import { IDTOReservaBox } from '@/models/DTO/IDTOAreaReserva';
import { EPeriodo } from '@/core/models/Enumeradores/EPeriodo';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';

export default defineComponent({
  name: 'ReservaMapaModal',
  components: {
  },
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    codigoArea: {
      type: Number,
      default: 0,
    },
    periodo: {
      type: Number,
      default: 0,
    },
    dataInicial: {
      type: String,
      default: '',
    },
    dataFinal: {
      type: String,
      default: '',
    },
  },
  emits: ['update:codigoArea', 'update:visivel', 'update:periodo', 'update:dataInicial', 'update:dataFinal', 'change'],
  setup(props, { emit }) {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta, obterCodigoRota,
    } = useAppBase();

    const computedCodigoArea = computed({
      get: () => props.codigoArea,
      set: (valor: number) => {
        emit('update:codigoArea', valor);
      },
    });

    const computedPeriodo = computed({
      get: () => props.periodo,
      set: (valor: number) => {
        emit('update:periodo', valor);
      },
    });

    const computedDataInicial = computed({
      get: () => props.dataInicial,
      set: (valor: string) => {
        emit('update:dataInicial', valor);
      },
    });

    const computedDataFinal = computed({
      get: () => props.dataFinal,
      set: (valor: string) => {
        emit('update:dataFinal', valor);
      },
    });

    const el = ref(null);
    const { width, height } = useElementSize(el);
    const route = useRoute();
    const servicoReserva = new ServicoReserva();
    const servicoUtilitario = new ServicoUtilitario();
    const gerenciadorAutenticacao = new GerenciadorAutenticacao();
    const state = reactive({
      passo: 1,
      mensagemAlerta: '',
      reservaCriacao: {} as IDTOReservaCriacao,
      areasDisponiveis: [] as IDTOAreaDisponibilidade[],
      periodoDisponivel: false,
      formaPagamento: {} as IFormaPagamento,
      area: {} as IArea,
      equipamentos: [] as IEquipamento[],
      boxSelecionado: {} as IAreaBox,
      statusBoxDisponivel: {} as IAreaBoxStatus,
      boxesReservados: [] as IDTOReservaBox[],
      coordenadasOriginaisBoxes: [] as IAreaBox[],
      mapaLarguraOriginal: 0,
      mapaAlturaOriginal: 0,
      chaveImg: 1,
      carregaPreviaMapa: false,
      quantidadeDias: 0,
      valorDiaria: 0,
      apresentarDadosCliente: false,
      indexPreview: -1,
      passoIndentificacao: 1,
      cpfCnpjIdentificacao: '',
      incluirEquipamentos: false,
      apresentarMapa: false,
      computedVisivel: computed({
        get: () => props.visivel,
        set: (valor: boolean) => {
          emit('update:visivel', valor);
        },
      }),
    });

    state.reservaCriacao.quantidadeParcelas = 1;
    state.reservaCriacao.reserva = {} as IReserva;
    state.reservaCriacao.reserva.status = EStatusReserva.Pendente;
    state.reservaCriacao.reserva.codigo = 0;
    state.reservaCriacao.reserva.codigoArea = 0;
    state.reservaCriacao.reserva.codigoBox = 0;
    state.reservaCriacao.reserva.codigoCliente = 0;
    state.reservaCriacao.reserva.codigoFormaPagamento = 4;
    state.reservaCriacao.reserva.qtdePessoasInteira = 1;
    state.reservaCriacao.reserva.qtdePessoasMeia = 0;
    state.reservaCriacao.reserva.qtdePessoasIsenta = 0;
    state.reservaCriacao.reserva.equipamentos = [];
    state.reservaCriacao.cliente = {} as ICliente;
    state.reservaCriacao.cliente.codigo = 0;
    state.boxSelecionado = {} as IAreaBox;
    state.equipamentos = [];

    state.reservaCriacao.dadosCartao = {} as IDTOReservaCartaoDadosCartao;
    state.reservaCriacao.dadosCartao.mesExpiracao = '01';
    state.reservaCriacao.dadosCartao.anoExpiracao = '2023';
    state.reservaCriacao.dadosTitularCartao = {} as IDTOReservaCartaoDadosTitularCartao;

    let corDisponivel = ref(state.statusBoxDisponivel.cor);

    const visible = ref<boolean>(false);

    const setVisible = (value:boolean): void => {
      visible.value = value;
    };

    function enviarAtualizacaoAltura(height:number) {
      window.parent.postMessage({ height }, '*');
    }

    function updateParkingSpacePositions() {
      state.chaveImg += 1;
      state.carregaPreviaMapa = true;

      setTimeout(() => {
        const parkingImage = document.querySelector('#AreaImgMapa') as any;
        if (parkingImage !== null) {
          const widthRatio = parkingImage.offsetWidth / state.mapaLarguraOriginal;
          const heightRatio = parkingImage.offsetHeight / state.mapaAlturaOriginal;

          state.area.mapaLargura = parkingImage.offsetWidth;
          state.area.mapaAltura = parkingImage.offsetHeight;
          state.area.boxes = UtilitarioGeral.instanciaObjetoLocal(state.coordenadasOriginaisBoxes);

          for (let index = 0; index < state.area.boxes.length; index += 1) {
            state.area.boxes[index].largura = (state.coordenadasOriginaisBoxes[index].largura * widthRatio);
            state.area.boxes[index].altura = (state.coordenadasOriginaisBoxes[index].altura * heightRatio);
            state.area.boxes[index].posicaoX = (state.coordenadasOriginaisBoxes[index].posicaoX * widthRatio);
            state.area.boxes[index].posicaoY = (state.coordenadasOriginaisBoxes[index].posicaoY * heightRatio);
          }

          state.carregaPreviaMapa = false;
        }
      }, 1000);
    }

    async function obterAreaComBoxReservados() {
      appBase.carregando = true;
      state.apresentarMapa = false;
      const areaReserva = await servicoReserva.obterAreaComBoxReservados(props.codigoArea, props.dataInicial, props.dataFinal, props.periodo);
      if (areaReserva !== undefined && areaReserva.area.codigo > 0) {
        state.area = areaReserva.area;

        if (state.area.tipoControle === ETipoControleArea.ControleEquipamento) {
          state.equipamentos = await servicoReserva.obterEquipamentos();
          if (UtilitarioGeral.validaLista(state.equipamentos)) {
            state.reservaCriacao.reserva.equipamentos = [];
            state.equipamentos.forEach((equipamento) => {
              const equipamentoReserva:IReservaEquipamento = {} as IReservaEquipamento;
              equipamentoReserva.codigo = 0;
              equipamentoReserva.codigoReserva = 0;
              equipamentoReserva.codigoEquipamento = equipamento.codigo;
              equipamentoReserva.nome = equipamento.nome;
              equipamentoReserva.quantidade = 0;
              equipamentoReserva.valor = equipamento.valor;
              state.reservaCriacao.reserva.equipamentos.push(equipamentoReserva);
            });
          }
        }
        state.statusBoxDisponivel = state.area.boxesStatus.find((c) => c.status === EBoxStatus.Disponivel) || {} as IAreaBoxStatus;
        corDisponivel = ref(state.statusBoxDisponivel.cor);
        state.boxesReservados = areaReserva.boxesReservados;
        state.mapaLarguraOriginal = state.area.mapaLargura;
        state.mapaAlturaOriginal = state.area.mapaAltura;
        state.coordenadasOriginaisBoxes = state.area.boxes;
        updateParkingSpacePositions();
        state.apresentarMapa = true;
      }

      appBase.carregando = false;
    }

    function limparDados() {
      state.area = {} as IArea;
      state.area.codigo = 0;
      state.area.status = true;
      state.area.tipoControle = 0;
      state.area.valoresPorPeriodo = [];
      state.area.boxesStatus = [];
      state.area.boxes = [];
      state.area.mapa = '';

      state.reservaCriacao.cliente = {} as ICliente;
      state.reservaCriacao.cliente.codigo = 0;
      state.reservaCriacao.cliente.status = true;
      state.reservaCriacao.cliente.tipoPessoa = 1;
      state.reservaCriacao.cliente.genero = 1;
      state.reservaCriacao.cliente.enderecos = [];
      state.reservaCriacao.cliente.enderecos[0].principal = true;
    }

    function obterCorStatus(box:IAreaBox):string {
      if (UtilitarioGeral.validaLista(state.boxesReservados)) {
        if (state.boxesReservados.some((c) => c.codigoBox === box.codigo)) {
          const statusReservado = state.area.boxesStatus.find((c) => c.status === EBoxStatus.Reservado);
          if (statusReservado !== undefined) {
            return statusReservado.cor;
          }
        }
      }

      const statusIndisponivel = state.area.boxesStatus.find((c) => c.status === EBoxStatus.Indisponivel);
      if (statusIndisponivel !== undefined) {
        return statusIndisponivel.cor;
      }

      return '';
    }

    function boxDisponivel(box:IAreaBox):boolean {
      if (UtilitarioGeral.validaLista(state.boxesReservados)) {
        if (state.boxesReservados.some((c) => c.codigoBox === box.codigo)) { return false; }
      }

      return box.codigoBoxStatus === state.statusBoxDisponivel.codigo;
    }
    function obtemTituloModal() {
      let tituloModal = 'Mapa de Reservas: ';
      if (state.area !== undefined && state.area.codigo > 0) {
        tituloModal += state.area.nome;
        tituloModal += ' Período: ';
        if (computedPeriodo.value === EPeriodo.Hoje) {
          tituloModal += ' Hoje ';
        } else if (computedPeriodo.value === EPeriodo.MesAtual) {
          tituloModal += ' Mês Atual';
        } else if (computedPeriodo.value === EPeriodo.Ultimos7Dias) {
          tituloModal += ' Últimos 7 Dias';
        } else if (computedPeriodo.value === EPeriodo.Ultimos15Dias) {
          tituloModal += ' Últimos 15 Dias';
        } else if (computedPeriodo.value === EPeriodo.Ultimos60Dias) {
          tituloModal += ' Últimos 60 Dias';
        } else if (computedPeriodo.value === EPeriodo.Ultimos90Dias) {
          tituloModal += ' Últimos 90 Dias';
        } else if (computedPeriodo.value === EPeriodo.Personalizado) {
          tituloModal += UtilitarioData.aplicaFormatoData(computedDataInicial.value);
          tituloModal += ` a ${UtilitarioData.aplicaFormatoData(computedDataFinal.value)}`;
        }
      }

      return tituloModal;
    }

    onMounted(() => {
      window.addEventListener('resize', updateParkingSpacePositions);
    });

    watch(async () => state.computedVisivel, async () => {
      if (state.computedVisivel) {
        await obterAreaComBoxReservados();
      }
    },
    { deep: true, immediate: true });

    watch(height, (value) => {
      enviarAtualizacaoAltura(value);
    },
    { deep: true, immediate: true });

    return {
      appBase,
      state,
      corDisponivel,
      el,
      height,
      ETipoControleArea,
      UtilitarioGeral,
      UtilitarioMascara,
      obterCorStatus,
      boxDisponivel,
      obterAreaComBoxReservados,
      visible,
      setVisible,
      computedCodigoArea,
      computedPeriodo,
      computedDataInicial,
      computedDataFinal,
      obtemTituloModal,
    };
  },
});
