
import {
  defineComponent, reactive, onBeforeMount,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/UI/Icone.vue';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IDTOReserva } from '@/models/DTO/IDTOReserva';
import ServicoReserva from '@/servicos/ServicoReserva';
import { IReservaParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/IReservaParametrosConsulta';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { EStatusResposta, IResposta } from '@/core/models/IResposta';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import { EStatusReserva } from '@/models/Enumeradores/EStatusReserva';
import ComboArea from '@/components/Areas/ComboArea.vue';
import ComboAreaBox from '@/components/Areas/ComboAreaBox.vue';
import SelecionarPeriodo from '@/core/components/UI/SelecionarPeriodo.vue';
import ComboStatusReserva from '@/components/Reservas/ComboStatusReserva.vue';
import SelecionarDataInicialFinal from '@/core/components/UI/SelecionarDataInicialFinal.vue';
import { EPeriodo } from '@/core/models/Enumeradores/EPeriodo';
import ReservaMapaModal from './ReservaMapaModal.vue';
import ReservaDisponibilidadeBoxModal from './ReservaDisponibilidadeBoxModal.vue';
import { obterItem, salvarItem } from '@/core/gerenciadores/GerenciadorLocalStorage';

export default defineComponent({
  name: 'ReservaListagem',
  components: {
    Icone,
    ComunicacaoApi,
    ComboArea,
    ComboAreaBox,
    SelecionarPeriodo,
    ComboStatusReserva,
    SelecionarDataInicialFinal,
    ReservaMapaModal,
    ReservaDisponibilidadeBoxModal,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta, apresentarMensagemAlerta,
    } = useAppBase();
    const servicoReserva = new ServicoReserva();
    const chaveFiltrosStorage = 'UP-FILTROS-RESERVAS';

    const state = reactive({
      reservas: [] as IDTOReserva[],
      parametrosConsulta: {} as IReservaParametrosConsulta,
      apresentarFiltros: false,
      apresentarMapaReservas: false,
      apresentarDisponibilidadeBoxes: false,
      ordenarPor: 'CodigoReservaDESC',
      opcoesOrdenacao: [] as IOptionString[],
    });
    let debounce = 0;
    state.parametrosConsulta.periodo = EPeriodo.Hoje;
    state.parametrosConsulta.status = EStatusReserva.Confirmada;
    state.opcoesOrdenacao = [];
    const ordemMaisRecentes:IOptionString = { value: 'CodigoReservaDESC', label: 'Mais recentes' } as IOptionString;
    const ordemNomeAsc:IOptionString = { value: 'NomeReservaASC', label: 'Nome (A-Z)' } as IOptionString;
    const ordemNomeDesc:IOptionString = { value: 'NomeReservaDESC', label: 'Nome (Z-A)' } as IOptionString;
    state.opcoesOrdenacao.push(ordemMaisRecentes);
    state.opcoesOrdenacao.push(ordemNomeAsc);
    state.opcoesOrdenacao.push(ordemNomeDesc);

    function salvarFiltros() {
      salvarItem(chaveFiltrosStorage, JSON.stringify(state.parametrosConsulta));
    }
    async function buscarDados() {
      if (!UtilitarioGeral.validaCodigo(state.parametrosConsulta.codigoArea)) {
        apresentarMensagemAlerta('Selecione uma área para pesquisar.');
        return;
      }

      salvarFiltros();
      appBase.carregando = true;
      state.apresentarFiltros = false;
      state.reservas = [];
      if (UtilitarioGeral.valorValido(state.ordenarPor)) {
        state.parametrosConsulta.ordenacao = [];
        state.parametrosConsulta.ordenacao.push(state.ordenarPor);
      }

      if (appBase.paginacao.paginaAtual > 0) { state.parametrosConsulta.numeroPagina = appBase.paginacao.paginaAtual; }
      if (appBase.paginacao.qtdeRegistrosPagina > 0) { state.parametrosConsulta.qtdeRegistrosPagina = appBase.paginacao.qtdeRegistrosPagina; }
      state.parametrosConsulta.qtdeRegistrosTotal = 100;
      const respostaConsulta = await servicoReserva.obterReservas(state.parametrosConsulta);
      if (UtilitarioGeral.validaLista(respostaConsulta.dados)) {
        state.reservas = respostaConsulta.dados;
        appBase.paginacao = respostaConsulta.paginacao;
      }

      appBase.carregando = false;
    }

    function buscarReservaPorNome() {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await buscarDados();
      }, 600);
    }
    async function limparFiltros() {
      state.parametrosConsulta.status = EStatusReserva.Confirmada;
      await buscarDados();
    }

    async function paginacaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = current;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }
    async function qtdeRegistrosPaginaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = 1;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }

    async function deletarReserva(codigo: number) {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor...');
      appBase.resposta = await servicoReserva.deletar(codigo);
      if (appBase.resposta.status === EStatusResposta.Sucesso) {
        const index = state.reservas.findIndex((u) => u.codigo === codigo);
        if (index >= 0) {
          state.reservas.splice(index, 1);
        }
      }
      apresentarResposta();
    }
    function confirmaExclusao(Reserva:IDTOReserva) {
      Modal.confirm({
        title: 'Você confirma a exclusão da Reserva do cliente:',
        content: `${Reserva.nomeCliente} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await deletarReserva(Reserva.codigo); },
      });
    }

    function obterDescricaoStatus(status:number):string {
      if (status === EStatusReserva.Pendente) { return 'Pendente'; }
      if (status === EStatusReserva.Confirmada) { return 'Confirmada'; }
      if (status === EStatusReserva.Concluida) { return 'Concluída'; }
      if (status === EStatusReserva.Cancelada) { return 'Cancelada'; }

      return '';
    }

    async function gerarRelatorioResumoDiario() {
      if (!UtilitarioGeral.validaCodigo(state.parametrosConsulta.codigoArea)) {
        apresentarMensagemAlerta('Selecione uma área.');
        return;
      }

      appBase.carregando = true;
      state.apresentarFiltros = false;

      if (UtilitarioGeral.valorValido(state.ordenarPor)) {
        state.parametrosConsulta.ordenacao = [];
        state.parametrosConsulta.ordenacao.push(state.ordenarPor);
      }

      const retornoRelatorio = await servicoReserva.gerarRelatorioResumoDiario(state.parametrosConsulta);
      if (retornoRelatorio.status === EStatusResposta.Sucesso) {
        window.open(retornoRelatorio.link, '_blank');
      } else if (retornoRelatorio.status === EStatusResposta.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusResposta.Erro) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      }

      appBase.carregando = false;
    }

    async function gerarRelatorioListagem() {
      if (!UtilitarioGeral.validaCodigo(state.parametrosConsulta.codigoArea)) {
        apresentarMensagemAlerta('Selecione uma área.');
        return;
      }

      appBase.carregando = true;
      state.apresentarFiltros = false;

      if (UtilitarioGeral.valorValido(state.ordenarPor)) {
        state.parametrosConsulta.ordenacao = [];
        state.parametrosConsulta.ordenacao.push(state.ordenarPor);
      }

      const retornoRelatorio = await servicoReserva.gerarRelatorioListagem(state.parametrosConsulta);
      if (retornoRelatorio.status === EStatusResposta.Sucesso) {
        window.open(retornoRelatorio.link, '_blank');
      } else if (retornoRelatorio.status === EStatusResposta.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusResposta.Erro) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      }

      appBase.carregando = false;
    }

    onBeforeMount(async () => {
      const filtrosSalvos = obterItem(chaveFiltrosStorage) as IReservaParametrosConsulta;
      if (filtrosSalvos !== undefined && filtrosSalvos.codigoArea > 0) {
        state.parametrosConsulta = filtrosSalvos;
        state.parametrosConsulta.valorPesquisa = '';
        await buscarDados();
      }
    });

    return {
      appBase,
      state,
      UtilitarioMascara,
      UtilitarioData,
      UtilitarioGeral,
      buscarDados,
      limparFiltros,
      buscarReservaPorNome,
      paginacaAlterada,
      qtdeRegistrosPaginaAlterada,
      confirmaExclusao,
      obterDescricaoStatus,
      gerarRelatorioResumoDiario,
      gerarRelatorioListagem,
    };
  },
});
