
import {
  defineComponent, reactive, watch, computed,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import {
  IArea,
} from '@/models/Entidades/IArea';
import ServicoReserva from '@/servicos/ServicoReserva';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EPeriodo } from '@/core/models/Enumeradores/EPeriodo';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IDTOReservaComunicacao, IDTOReservaDisponibilidadeBox, IDTOReservaDisponibilidadeBoxDetalhe } from '@/models/DTO/IDTOReservaDisponibilidadeBox';
import storeApp from '@/store/storeApp';
import router from '@/router';

export default defineComponent({
  name: 'ReservaDisponibilidadeBoxModal',
  components: {
  },
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    codigoArea: {
      type: Number,
      default: 0,
    },
    periodo: {
      type: Number,
      default: 0,
    },
    dataInicial: {
      type: String,
      default: '',
    },
    dataFinal: {
      type: String,
      default: '',
    },
  },
  emits: ['update:codigoArea', 'update:visivel', 'update:periodo', 'update:dataInicial', 'update:dataFinal', 'change'],
  setup(props, { emit }) {
    const {
      appBase,
    } = useAppBase();

    const computedCodigoArea = computed({
      get: () => props.codigoArea,
      set: (valor: number) => {
        emit('update:codigoArea', valor);
      },
    });

    const computedPeriodo = computed({
      get: () => props.periodo,
      set: (valor: number) => {
        emit('update:periodo', valor);
      },
    });

    const computedDataInicial = computed({
      get: () => props.dataInicial,
      set: (valor: string) => {
        emit('update:dataInicial', valor);
      },
    });

    const computedDataFinal = computed({
      get: () => props.dataFinal,
      set: (valor: string) => {
        emit('update:dataFinal', valor);
      },
    });

    const servicoReserva = new ServicoReserva();

    const state = reactive({
      apresentarBoxes: false,
      area: {} as IArea,
      disponibilidadesBoxes: [] as IDTOReservaDisponibilidadeBox[],
      computedVisivel: computed({
        get: () => props.visivel,
        set: (valor: boolean) => {
          emit('update:visivel', valor);
        },
      }),
    });

    state.disponibilidadesBoxes = [];

    async function obterExtratosBoxes() {
      state.apresentarBoxes = false;
      state.disponibilidadesBoxes = [];
      appBase.carregando = true;

      state.area = await servicoReserva.obterAreaResumida(props.codigoArea);
      const disponibilidadesBoxes = await servicoReserva.obterReservasDisponibilidadeBox(props.codigoArea, props.dataInicial, props.dataFinal, props.periodo);
      if (UtilitarioGeral.validaLista(disponibilidadesBoxes)) {
        state.disponibilidadesBoxes = disponibilidadesBoxes;
        state.apresentarBoxes = true;
      }

      appBase.carregando = false;
    }

    function obtemTituloModal() {
      let tituloModal = 'Disponibilidade de Reservas: ';
      if (state.area !== undefined && state.area.codigo > 0) {
        tituloModal += state.area.nome;
        tituloModal += ' Período: ';
        if (computedPeriodo.value === EPeriodo.Hoje) {
          tituloModal += ' Hoje ';
        } else if (computedPeriodo.value === EPeriodo.MesAtual) {
          tituloModal += ' Mês Atual';
        } else if (computedPeriodo.value === EPeriodo.Ultimos7Dias) {
          tituloModal += ' Últimos 7 Dias';
        } else if (computedPeriodo.value === EPeriodo.Ultimos15Dias) {
          tituloModal += ' Últimos 15 Dias';
        } else if (computedPeriodo.value === EPeriodo.Ultimos60Dias) {
          tituloModal += ' Últimos 60 Dias';
        } else if (computedPeriodo.value === EPeriodo.Ultimos90Dias) {
          tituloModal += ' Últimos 90 Dias';
        } else if (computedPeriodo.value === EPeriodo.Personalizado) {
          tituloModal += UtilitarioData.aplicaFormatoData(computedDataInicial.value);
          tituloModal += ` a ${UtilitarioData.aplicaFormatoData(computedDataFinal.value)}`;
        }
      }

      return tituloModal;
    }

    function criarReserva(detalhe:IDTOReservaDisponibilidadeBoxDetalhe) {
      const reservaComunicacao: IDTOReservaComunicacao = {} as IDTOReservaComunicacao;
      reservaComunicacao.token = UtilitarioGeral.gerarTokenUnico();
      reservaComunicacao.codigoArea = props.codigoArea;
      reservaComunicacao.codigoBox = detalhe.codigoBox;
      reservaComunicacao.dataCheckIn = detalhe.dataCheckIn;
      reservaComunicacao.dataChekOut = detalhe.dataChekOut;
      storeApp.mutations.adicionarReserva(reservaComunicacao);
      router.push({ name: 'ReservaCadastroCriar', query: { token: reservaComunicacao.token } });
    }

    watch(async () => state.computedVisivel, async () => {
      if (state.computedVisivel) {
        await obterExtratosBoxes();
      }
    },
    { deep: true, immediate: true });

    return {
      appBase,
      state,
      UtilitarioGeral,
      UtilitarioData,
      UtilitarioMascara,
      computedCodigoArea,
      computedPeriodo,
      computedDataInicial,
      computedDataFinal,
      obtemTituloModal,
      criarReserva,
    };
  },
});
