
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useRoute } from 'vue-router';
import { useAppBase } from '@/core/composables/AppBase';
import { IReserva, IReservaEnergia, IReservaEquipamento } from '@/models/Entidades/IReserva';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import SelecionarData from '@/core/components/UI/SelecionarData.vue';
import CampoNumerico from '@/core/components/UI/CampoNumerico.vue';
import Icone from '@/core/components/UI/Icone.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import ServicoReserva from '@/servicos/ServicoReserva';
import { IResposta } from '@/core/models/IResposta';
import { EStatusReserva } from '@/models/Enumeradores/EStatusReserva';
import ComboArea from '@/components/Areas/ComboArea.vue';
import ComboAreaBox from '@/components/Areas/ComboAreaBox.vue';
import ComboStatusReserva from '@/components/Reservas/ComboStatusReserva.vue';
import ComboCliente from '@/components/ComboCliente.vue';
import ComboEquipamento from '@/components/ComboEquipamento.vue';
import ComboFormaPagamento from '@/components/ComboFormaPagamento.vue';
import ComboTipoPessoa from '@/components/ComboTipoPessoa.vue';
import CampoCpf from '@/core/components/UI/CampoCpf.vue';
import CampoCnpj from '@/core/components/UI/CampoCnpj.vue';
import CampoTelefone from '@/core/components/UI/CampoTelefone.vue';
import CampoEmail from '@/core/components/UI/CampoEmail.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IArea } from '@/models/Entidades/IArea';
import { ETipoControleArea } from '@/models/Enumeradores/ETipoControle';
import { ICliente } from '@/models/Entidades/ICliente';
import { ETipoPessoa } from '@/models/Enumeradores/ETipoPessoa';
import ServicoCliente from '@/servicos/ServicoCliente';
import { obterItem } from '@/core/gerenciadores/GerenciadorLocalStorage';
import { IReservaParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/IReservaParametrosConsulta';
import storeApp from '@/store/storeApp';

export default defineComponent({
  name: 'ReservaCadastro',
  components: {
    ComunicacaoApi,
    ComboArea,
    ComboAreaBox,
    ComboStatusReserva,
    ComboCliente,
    ComboEquipamento,
    ComboFormaPagamento,
    SelecionarData,
    CampoNumerico,
    Icone,
    EditorHtml,
    ComboTipoPessoa,
    CampoCpf,
    CampoCnpj,
    CampoTelefone,
    CampoEmail,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta, apresentarRespostaRedirecionamento, obterCodigoRota, apresentarMensagemAlerta,
    } = useAppBase();
    const servicoReserva = new ServicoReserva();
    const servicoCliente = new ServicoCliente();
    const chaveFiltrosStorage = 'UP-FILTROS-RESERVAS';

    const route = useRoute();
    const state = reactive({
      reserva: {} as IReserva,
      area: {} as IArea,
      equipamento: {} as IReservaEquipamento,
      apresentarCadastroNovoCliente: false,
      apresentarModalCadastroCliente: false,
      cadastrandoCliente: false,
      valorDigitadoCliente: '',
      novoCliente: {} as ICliente,
    });

    async function obterArea(preservarCodigoBox = false) {
      state.area = await servicoReserva.obterAreaResumida(state.reserva.codigoArea);
      if (!preservarCodigoBox) { state.reserva.codigoBox = 0; }
    }
    async function obterReserva(codigo:number) {
      appBase.carregando = true;
      state.reserva = await servicoReserva.obter(codigo);
      await obterArea(true);
      appBase.carregando = false;
    }

    function limparClienteNovo() {
      state.novoCliente = {} as ICliente;
      state.novoCliente.codigo = 0;
      state.novoCliente.genero = 1;
      state.novoCliente.enderecos = [];
      state.novoCliente.tipoPessoa = ETipoPessoa.Fisica;
      state.novoCliente.emailConfirmado = true;
      state.novoCliente.status = true;
    }

    function limparEquipamento() {
      state.equipamento = {} as IReservaEquipamento;
      state.equipamento.codigo = 0;
      state.equipamento.codigoReserva = 0;
      state.equipamento.quantidade = 1;
      state.equipamento.valor = 0;
    }

    function adicionarEquipamento() {
      state.reserva.equipamentos.push(UtilitarioGeral.instanciaObjetoLocal(state.equipamento));
      limparEquipamento();
    }

    function removerEquipamento(index:number) {
      state.reserva.equipamentos.splice(index, 1);
    }

    function limparDados() {
      state.reserva = {} as IReserva;
      state.reserva.codigo = 0;
      state.reserva.status = EStatusReserva.Pendente;
      state.reserva.qtdePessoasInteira = 0;
      state.reserva.qtdePessoasMeia = 0;
      state.reserva.qtdePessoasIsenta = 0;
      state.reserva.equipamentos = [];
      limparEquipamento();
      state.reserva.consumoEnergia = {} as IReservaEnergia;
      state.reserva.consumoEnergia.codigo = 0;
      state.reserva.consumoEnergia.codigoReserva = 0;
      state.reserva.consumoEnergia.medidorInicial = 0;
      state.reserva.consumoEnergia.medidorFinal = 0;
      state.reserva.consumoEnergia.observacoes = '';

      limparClienteNovo();
      state.apresentarCadastroNovoCliente = false;
      state.apresentarModalCadastroCliente = false;
      state.valorDigitadoCliente = '';
    }

    function apresentarCadastroCliente() {
      state.apresentarModalCadastroCliente = true;
      if (UtilitarioGeral.valorValido(state.valorDigitadoCliente)) {
        const valorDigitado = UtilitarioGeral.obterApenasNumeros(state.valorDigitadoCliente);
        if (UtilitarioGeral.valorValido(valorDigitado)) {
          if (valorDigitado.length === 11) {
            state.novoCliente.tipoPessoa = ETipoPessoa.Fisica;
            state.novoCliente.cpfCnpj = valorDigitado;
          } else if (valorDigitado.length === 14) {
            state.novoCliente.tipoPessoa = ETipoPessoa.Juridica;
            state.novoCliente.cpfCnpj = valorDigitado;
          }
        } else {
          state.novoCliente.nomeRazaoSocial = state.valorDigitadoCliente;
        }
      }
    }

    async function cadastrarNovoCliente() {
      appBase.resposta = {} as IResposta;

      if (state.novoCliente.tipoPessoa === ETipoPessoa.Fisica) {
        state.novoCliente.identificacao = state.novoCliente.nomeRazaoSocial;
      }
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações de Cliente.');
      appBase.resposta = await servicoCliente.incluir(state.novoCliente);

      apresentarResposta();
      if (appBase.resposta.codigo > 0) {
        state.reserva.codigoCliente = appBase.resposta.codigo;
        limparClienteNovo();
        state.apresentarCadastroNovoCliente = false;
        state.apresentarModalCadastroCliente = false;
        state.valorDigitadoCliente = '';
      }
    }

    async function consultarDisponibilidadeBoxSelecionado(campoAlterado: string) {
      if (campoAlterado === 'DataCheckIn' && state.reserva.codigoArea === 0) { return; }
      if (campoAlterado === 'DataCheckOut' && state.reserva.codigoArea === 0) { return; }

      if (!UtilitarioGeral.valorValido(state.reserva.dataCheckIn)) {
        apresentarMensagemAlerta('Por favor, prencha a sua data de Check-in.');
        state.reserva.codigoBox = 0;
        return;
      }

      if (!UtilitarioGeral.valorValido(state.reserva.dataCheckOut)) {
        apresentarMensagemAlerta('Por favor, prencha a sua data de Check-Out.');
        state.reserva.codigoBox = 0;
        return;
      }

      if (!UtilitarioGeral.validaCodigo(state.reserva.codigoArea)) {
        apresentarMensagemAlerta('Por favor, selecione a área desejada!');
        state.reserva.codigoBox = 0;
        return;
      }

      if (!UtilitarioGeral.validaCodigo(state.reserva.codigoBox)) {
        apresentarMensagemAlerta('Por favor, selecione o Box desejado!');
        return;
      }

      const areaReserva = await servicoReserva.obterAreaComBoxReservados(state.reserva.codigoArea, state.reserva.dataCheckIn, state.reserva.dataCheckOut);
      if (state.reserva.codigo === 0) {
        const boxReservado = areaReserva.boxesReservados.some((c) => c.codigoBox === state.reserva.codigoBox);
        if (boxReservado) {
          apresentarMensagemAlerta('O Box selecionado já possui reserva neste período.');
          state.reserva.codigoBox = 0;
        }
      } else {
        const boxReservado = areaReserva.boxesReservados.find((c) => c.codigoBox === state.reserva.codigoBox && c.codigoReserva !== state.reserva.codigo);
        if (boxReservado !== undefined) {
          apresentarMensagemAlerta('O Box selecionado já possui reserva neste período.');
          state.reserva.codigoBox = 0;
        }
      }
    }

    function apresentarBotaoCadastroCliente():boolean {
      return !UtilitarioGeral.validaCodigo(state.reserva.codigoCliente);
    }

    onBeforeMount(async () => {
      limparDados();
      const codigoReserva = obterCodigoRota();
      if (codigoReserva > 0) {
        await obterReserva(codigoReserva);
      } else {
        const filtrosSalvos = obterItem(chaveFiltrosStorage) as IReservaParametrosConsulta;
        if (filtrosSalvos !== undefined && filtrosSalvos.codigoArea > 0) {
          state.reserva.codigoArea = filtrosSalvos.codigoArea;
        }

        const tokenStore = String(route.query.token);
        if (UtilitarioGeral.valorValido(tokenStore)) {
          const novaReserva = storeApp.getters.obterReserva(tokenStore);
          if (novaReserva !== undefined && novaReserva.codigoBox > 0) {
            state.reserva.codigoArea = novaReserva.codigoArea;
            state.reserva.codigoBox = novaReserva.codigoBox;
            state.reserva.dataCheckIn = novaReserva.dataCheckIn;
            state.reserva.dataCheckOut = novaReserva.dataChekOut;
            storeApp.mutations.removerReserva(tokenStore);
          }
        }
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações da Reserva.');
      if (state.reserva.codigo === 0) {
        appBase.resposta = await servicoReserva.incluir(state.reserva);
      } else {
        appBase.resposta = await servicoReserva.atualizar(state.reserva);
      }

      apresentarRespostaRedirecionamento('Reservas');
    }

    return {
      appBase,
      state,
      ETipoControleArea,
      salvar,
      adicionarEquipamento,
      removerEquipamento,
      obterArea,
      cadastrarNovoCliente,
      apresentarCadastroCliente,
      consultarDisponibilidadeBoxSelecionado,
      apresentarBotaoCadastroCliente,
    };
  },
});
